export const PROJECT_NAME = 'flow-web';
export type ENV_TYPE = 'LOCAL' | 'DAILY' | 'PUBLISH';
export const ENV: Record<ENV_TYPE, ENV_TYPE> = {
    LOCAL: 'LOCAL',
    DAILY: 'DAILY',
    PUBLISH: 'PUBLISH',
};
export const ENV_PROTOCOL_HOST: Record<ENV_TYPE, string> = {
    //LOCAL: 'http://47.110.241.252:8080',
    LOCAL: '',
    DAILY: '',
    PUBLISH: '',
};
export const API_PREFIX = '/api';

export class Config {
    static getProjectName(): string {
        return PROJECT_NAME;
    }

    static getEnv(): ENV_TYPE {
        const { hostname } = window.location;
        if (hostname === 'localhost') {
            return ENV.LOCAL;
        }
        if (hostname === 'o.zys.me') {
            return ENV.DAILY;
        }
        return ENV.PUBLISH;
    }

    static getUrlPrefix(): string {
        const env = Config.getEnv();
        return ENV_PROTOCOL_HOST[env] + API_PREFIX;
    }
}
//统一新增 消息头
export const getTestnetHeaders = () => ({
    'H-AUTH-TOKEN': localStorage.getItem('accessToken') || '955cd5ce8a04b0dd464cda677b685e28',
    'H-PLATFORM-INFO': 'h5'
})
// 根据 当前环境 获取登陆环境
export const getLoginUrl = () => {

    return "/login"

    // return url
}
export const getNewUrl = () => {
    let currentPageUrl = location.host;

    // url = currentPageUrl +'/aaa.html'http://test.sg-chain.com/
    //  console.log(url,"0000000000000")//https://dev.sg-chain.com/
    let url = 'https://sg-chain.com/aaa.html' || ''
    if (String(window.location.href).includes('uat') || (window.location.href).includes('test')) {
        url = 'https://uat.sg-chain.com/aaa.html' || ''
    }
    if (String(window.location.href).includes('dev')) {
        url = 'https://dev.sg-chain.com/aaa.html' || ''
    }
    if (String(window.location.href).includes('localhost')) {
        console.log(window.location.host);
        if (window.location.port == '3000/aaa.html') {

            url = "http://localhost:3000/aaa.html" || ''
        } else {

            url = "http://localhost:3000/aaa.html" || ''
        }
    }
    return url
}
