import { useState, useEffect, useRef } from "react"
import moment from "moment"
import { EnvironmentOutlined, ProjectOutlined, MenuOutlined } from "@ant-design/icons"
import { Tabs, Tooltip, Divider, Select, Space, Popover } from 'antd';
import { v4 as uuid } from "uuid"
import Partition from "@/assets/Partition.svg"
import PartitionActive from "@/assets/Partition_active.svg"
import "./resetTabs.scss"
import "./mapLeftInfo.scss"
import arrow from "@/assets/double_right.svg"

import SearchHeaderTitle from "@/components/SearchHeaderTitle"
import classnames from "classnames"
import { getbussinessList, getvisiblesList, getworkflowsList } from "@/api"
export default function MapLeftInfo(props) {
    const { freshItemKey, activeItem, apiHeader, freshListKey } = props;
    const [currentKey, setcurrentKey] = useState(1)
    const [tabSearchValue, settabSearchValue] = useState('')
    const refSearchHeaderTitle = useRef(null);
    const [hiddenContent, sethiddenContent] = useState(true);
    const [BileList, setBileList] = useState([])
    const [BileListOld, setBileListOld] = useState([])
    const [endPorts, setendPorts] = useState([])
    const [startPorts, setstartPorts] = useState([])
    const [endPortValue, setendPortValue] = useState('')
    const [startPortValue, setstartPortValue] = useState('')
    const [etaValue, setetaValue] = useState('')
    const [selectValues, setselectValues] = useState({
        'start_port': "",
        'end_port': "",
        'eta': "",
    })

    useEffect(() => {
        getBileList(currentKey);
    }, [freshListKey])

    useEffect(() => {

        setBileList(BileList?.map(m => m.ladingCode === activeItem?.code ? { ...m, active: true } : { ...m, active: false }))
        let activeIndex = BileList.findIndex(m => m?.ladingCode === activeItem?.code);
        // debugger
        activeIndex > 0 ? (document.querySelector('.list_content').scrollTop = activeIndex * 80) : ''
    }, [freshItemKey])
    // 非协作list
    const getBileList = async (currentKey, keyword) => {
        console.log('currentKey', currentKey);
        let api = getbussinessList;
        if (currentKey == 2) {
            api = getvisiblesList
        }
        if (currentKey == 3) {
            api = getworkflowsList
        }
        // 清空
        setBileList([])
        let { data } = await api({ keyword, pageSize: 100 }, apiHeader);
        let allList = data?.list || [];

        allList = allList.map(m => {
            let business = m?.business;
            let extas = m?.extas;
            let nowTime = new Date().getTime();
            let etaTime = ''
            let daysFromNow = ''
            // debugger
            if (extas?.eta && new Date(extas?.eta) != "Invalid Date") {
                etaTime = new Date(extas?.eta).getTime();
                daysFromNow = Math.ceil((etaTime - nowTime) / 1000 / 60 / 60 / 24) + 1
            }
            const { gmtCreate, startPort, eta, subType, type } = { ...business, ...extas };
            return { ...m, daysFromNow, gmtCreate, startPort, eta, subType, type }
        })
        // keyword ==undefined时是tab 切换 “” 时是情况搜索
        if (keyword == undefined) {
            // debugger
            setBileList(filterBileList(allList, {
                'start_port': "",
                'end_port': "",
                'eta': "",
            }))
        } else {
            setBileList(filterBileList(allList, selectValues))
        }
        setBileListOld(allList)
        setstartPorts(getSelectOptions(allList, 'startPort'))
        setendPorts(getSelectOptions(allList, 'port'))
    }
    const getSelectOptions = (list, objKey) => {
        let arr = Array.from(new Set(list?.filter(f => !!f?.[objKey]).map(m => m?.[objKey])))
        // debugger
        return arr.map(m => ({ value: m, label: m }));
    }
    const clearSearchValue = () => {
        refSearchHeaderTitle.current?.clearSearchValue()
    }
    // toggle haidd
    const tabClick = (e) => {
        console.log('tabClick', e);

        if (currentKey == e) {
            return sethiddenContent(!hiddenContent)
        }
        getBileList(e)
        sethiddenContent(false)
        if (e == 1) {
            props.initMapMarks('');

        }
        if (e == 2) {
            props.initMapMarks('visible');
        }
        if (e == 3) {
            props.initMapMarks('workflow');
        }
    }
    const onChange = (key) => {
        console.log('key', key);
        setcurrentKey(key)
        clearSearchValue()
        settabSearchValue('')
    };
    const getSelectValue = (v) => {
        console.log('getSelectValue', v, currentKey);
        settabSearchValue(v)
        getBileList(currentKey, v)
    }
    const handleItem = (d) => {
        setBileList(BileList.map(m => m.code == d.code ? { ...m, active: true } : { ...m, active: false }))
        props?.setBlink(d);
    }
    const filterBileList = (arr, parames) => {
        if (parames["start_port"]) {
            arr = arr.filter(f => (f?.startPort == parames?.start_port))
        }
        if (parames["end_port"]) {
            arr = arr.filter(f => (f?.port == parames?.end_port))
        }
        if (parames["eta"]) {
            arr = arr.filter(f => f?.daysFromNow > 0 && f?.daysFromNow && parames?.eta >= f?.daysFromNow)
        }
        return arr
    }
    // 装货港 卸货港 eta 搜索
    const handleSelect = (v, t) => {
        setselectValues({ ...selectValues, [t]: v });
        console.log('setselectValues', selectValues);
        let parames = { ...selectValues, [t]: v };
        setBileList(filterBileList(BileListOld, parames))
    }

    // 装货港 卸货港 eta 清除搜索
    const handleClear = (t) => {
        setselectValues({ ...selectValues, [t]: '' });
        let parames = { ...selectValues, [t]: '' };
        setBileList(filterBileList(BileListOld, parames))
    }
    const t = (m) => {
        if (!m?.tags || m?.tags?.length == 0) {
            return (<>
                <span>
                    创建于 {moment(m?.business?.gmtCreate).format("YYYY-MM-DD") || " "}
                </span>
            </>)
        }
        return (<>
            <span>ETA {m?.eta || "-"}</span><Divider type="vertical" /><span>
                创建于 {moment(m?.business?.gmtCreate).format("YYYY-MM-DD") || " "}
            </span>
        </>)
    }
    const renderItemsChildren = () => {
        const cornerPopover = (m) => (<div className="item_right_corner_content scrollbar_custom" >
            {m?.noteList?.map((n, i) =>
                <div key={i} className="item_right_corner_item" style={{ marginTop: i > 0 ? 8 : 0 }}>
                    {n}

                </div>)}
        </div>)
        return (
            <div className="custom_tabs_content" style={{ backgroundColor: "#fff" }}>
                <SearchHeaderTitle ref={refSearchHeaderTitle} className="custom_search_style" getSelectValue={getSelectValue} title=""></SearchHeaderTitle>
                <Divider></Divider>
                <div className="select_group">
                    <Space size={[12, 12]}>
                        <Select
                            placeholder="装货港"
                            allowClear={true}
                            style={{ width: 96 }}
                            onClear={() => handleClear('start_port')}
                            onSelect={(v) => handleSelect(v, 'start_port')}
                            options={startPorts}
                        ></Select>
                        <Select
                            // open={true}
                            onClear={() => handleClear('end_port')}
                            onSelect={(v) => handleSelect(v, 'end_port')}
                            allowClear={true}
                            placeholder="卸货港"
                            style={{ width: 96 }}
                            options={endPorts}
                        ></Select>
                        <Select
                            allowClear={true}
                            placeholder="ETA"
                            style={{ width: 120 }}
                            onClear={() => handleClear('eta')}
                            onSelect={(v) => handleSelect(v, 'eta')}
                            options={[
                                { value: '1', label: '今日' },
                                { value: '3', label: '三天' },
                                { value: '7', label: '一周' },
                                { value: '14', label: '两周' },
                            ]}
                        // open={true}

                        ></Select>
                    </Space>
                </div>
                <div className="list_wrap">
                    <div className="list_content">
                        <div className="item_container ">
                            {BileList.length == 0 ? <div className="item_empty">暂无业务，请前往「工作台」创建</div> :
                                BileList.map((m, i) => <div onClick={() => handleItem(m)}
                                    className={classnames({ 'item_actived': m?.active, [`item_${m?.business?.subType || m?.business?.type}`]: true, 'notTags': !m?.tags || m?.tags?.length == 0 }, 'item_wrap')} key={i}>
                                    {m?.noteList && m?.noteList?.length > 0 && <Popover
                                        overlayClassName="popover_custom_corner"
                                        className="item_right_corner"
                                        // trigger="click"
                                        content={cornerPopover(m)}
                                        placement="right">
                                        <div className="item_right_corner3">
                                            <div className="item_icon">
                                                <MenuOutlined style={{ fontSize: 8, color: "#0166ff" }} />
                                            </div>
                                        </div>
                                    </Popover>}

                                    <div className="item_title">
                                        <Tooltip
                                            color="white"
                                            title={<div >
                                                {m?.contractNo ?
                                                    <span style={{ color: "rgba(0,0,0,0.8)", fontWeight: 500 }}>{
                                                        m?.contractNo} <span style={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}> -{m?.code}</span>
                                                    </span>

                                                    : <span style={{ color: "rgba(0,0,0,0.8)", fontWeight: 500 }}>{m?.code}</span>
                                                }

                                            </div>} >


                                            <div style={{ maxWidth: 260, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                {m?.contractNo ?
                                                    <span style={{ color: "rgba(0,0,0,0.8)", fontWeight: 500 }}>{
                                                        m?.contractNo} <span style={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}> -{m?.code}</span>
                                                    </span>

                                                    : <span style={{ color: "rgba(0,0,0,0.8)", fontWeight: 500 }}>{m?.code}</span>
                                                }

                                            </div>
                                        </Tooltip>

                                        <a style={{ fontSize: 12, fontWeight: 400, color: "#0166ff" }}> {m?.port}</a>
                                    </div>
                                    <div className="item_content">
                                        {m?.tags?.filter(m => m)?.map((item, index) => (
                                            <span key={index}>{item}</span>
                                        ))}
                                        {!m?.tags?.includes("泥炭") && m?.profit?.profit && <span style={{ color: m?.profit?.profit > 0 ? 'red' : "rgba(42, 192, 97, 1)" }}>盈亏 &nbsp;{m?.profit?.profit > 0 ? "+" : ""}{parseInt(m?.profit?.profit).toLocaleString()}</span>}
                                    </div>
                                    <div className="item_tiem">
                                        {t(m)}

                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const items = [
        {
            key: '1',
            label: (<div className="custom_tab_label"> <ProjectOutlined style={{ fontSize: 20 }} /> 所有业务</div>),
            children: renderItemsChildren()
            ,
        },
        {
            key: '2',
            label: (<div className="custom_tab_label"> <EnvironmentOutlined style={{ fontSize: 20 }} />非协业务 </div>),
            children: renderItemsChildren(),
        },

        {
            key: '3',
            label: (<div className="custom_tab_label">{currentKey != 3 ? <img className="anticon partition_active" src={PartitionActive} /> : <img className="anticon partition" src={Partition} />}协作业务 </div>),
            children: renderItemsChildren(),
        }
    ];
    const setBtnCenter = (window.innerHeight - 344 - 48) / 2;
    const renderTabBar = (props, DefaultTabBar) => (
        <div
            className="current_render_tabs"
        >
            <DefaultTabBar
                {...props}
            />
            {!hiddenContent && <span onClick={() => sethiddenContent(!hiddenContent)} className="close_leftcontent"><img src={arrow} /></span>}

        </div>
    );
    return (
        <Tabs destroyInactiveTabPane={true} tabPosition="left" tabBarGutter={16} className={hiddenContent ? "hiddle_tabs_holder map_left_info_container" : "map_left_info_container"} defaultActiveKey="1" renderTabBar={renderTabBar} items={items} onTabClick={tabClick} onChange={onChange} />
    )
}