import { useState, useEffect, useRef } from "react"
import moment from "moment"
import { EnvironmentOutlined, ProjectOutlined, TeamOutlined, MenuOutlined } from "@ant-design/icons"
import { Tabs, Button, Divider, Select, Space, Popover } from 'antd';
import { v4 as uuid } from "uuid"
import Partition from "@/assets/Partition.svg"
import PartitionActive from "@/assets/Partition_active.svg"
import "./resetTabs.scss"
import "./mapLeftInfo.scss"
import SelectSalesmanForBoss from "@/components/SelectSalesmanForBoss";
import arrow from "@/assets/double_right.svg"
import SearchHeaderTitle from "@/components/SearchHeaderTitle"
import classnames from "classnames"
import { getbussinessList, getvisiblesList, getworkflowsList } from "@/api"
export default function EnterpressMapLeftInfo(props) {
    const { freshItemKey, activeItem, apiHeader, freshListKey } = props;
    const [currentKey, setcurrentKey] = useState('enterpress')
    const [tabSearchValue, settabSearchValue] = useState('')
    const refSearchHeaderTitle = useRef(null);
    const [hiddenContent, sethiddenContent] = useState(true);
    const [isEnterPress, setisEnterPress] = useState(false);
    const [isshwoTabs, setisshwoTabs] = useState(false);
    const [BileList, setBileList] = useState([])
    const [BileListOld, setBileListOld] = useState([])
    const [endPorts, setendPorts] = useState([])
    const [startPorts, setstartPorts] = useState([])
    const [endPortValue, setendPortValue] = useState('')
    const [visibleSum, setvisibleSum] = useState(0)
    const [workflowSum, setworkflowSum] = useState(0)
    const [selectedItem, setselectedItem] = useState({ name: '全部', code: "0" });
    const [selectValues, setselectValues] = useState({
        'start_port': "",
        'end_port': "",
        'eta': "",
    })


    useEffect(() => {
        getBileList(currentKey);
    }, [freshListKey])

    useEffect(() => {
        console.log('freshItemKey', freshItemKey);
        if (currentKey) {
            setBileList(BileList?.map(m => m.ladingCode === activeItem?.code ? { ...m, active: true } : { ...m, active: false }))
            let activeIndex = BileList.findIndex(m => m?.ladingCode === activeItem?.code);
            // debugger
            activeIndex > 0 ? (document.querySelector('.list_content').scrollTop = activeIndex * 80) : ''

        }


    }, [freshItemKey])

    // 非 协作list
    const getBileList = async (currentKey, keyword) => {
        let api = getbussinessList;
        if (currentKey == 'visible') {
            api = getvisiblesList
        }
        if (currentKey == 'workflow') {
            api = getworkflowsList
        }
        try {


            let { data: { list: allList, total } } = await api({ keyword, pageSize: 100 }, apiHeader);
            if (currentKey == 'enterpress') {
                let selfSum = allList.filter(m => m?.business?.type == "visible").length;

                setvisibleSum(selfSum)
                setworkflowSum(total - selfSum)
            }
            allList = allList.map(m => {
                let business = m?.business;
                let extas = m?.extas;
                let nowTime = new Date().getTime();
                let etaTime = ''
                let daysFromNow = ''
                // debugger
                if (extas?.eta && new Date(extas?.eta) != "Invalid Date") {
                    etaTime = new Date(extas?.eta).getTime();
                    daysFromNow = Math.ceil((etaTime - nowTime) / 1000 / 60 / 60 / 24) + 1
                }
                const { gmtCreate, startPort, eta, subType, type } = { ...business, ...extas };
                return { ...m, daysFromNow, gmtCreate, startPort, eta, subType, type }
            })
            // keyword ==undefined时是tab 切换 “” 时是情况搜索
            if (keyword == undefined) {
                // debugger
                setBileList(filterBileList(allList, {
                    'start_port': "",
                    'end_port': "",
                    'eta': "",
                }))
            } else {
                setBileList(filterBileList(allList, selectValues))
            }
            setBileListOld(allList)
            setstartPorts(getSelectOptions(allList, 'startPort'))
            setendPorts(getSelectOptions(allList, 'port'))
        } catch (error) {
            console.error('list', error);
        }
    }
    const getSelectOptions = (list, objKey) => {
        let arr = Array.from(new Set(list?.filter(f => !!f?.[objKey]).map(m => m?.[objKey])))
        // debugger
        return arr.map(m => ({ value: m, label: m }));
    }
    const clearSearchValue = () => {
        refSearchHeaderTitle.current?.clearSearchValue()
    }
    // toggle haidd
    const tabClick = (e) => {

        if (currentKey == e) {
            return sethiddenContent(!hiddenContent)
        }
        getBileList(e)
        sethiddenContent(false)
        if (e == 'all') {
            props.initMapMarks('');

        }
        if (e == 'visible') {
            props.initMapMarks('visible');
        }
        if (e == 'workflow') {
            props.initMapMarks('workflow');
        }



    }
    const onChange = (key) => {
        setcurrentKey(key)
        clearSearchValue()
        settabSearchValue('')
    };
    const getSelectValue = (v) => {
        settabSearchValue(v)
        getBileList(currentKey, v)
    }
    const handleItem = (d) => {
        setBileList(BileList.map(m => m.code == d.code ? { ...m, active: true } : { ...m, active: false }))
        props?.setBlink(d);
    }
    const filterBileList = (arr, parames) => {
        if (parames["start_port"]) {
            arr = arr.filter(f => (f?.startPort == parames?.start_port))
        }
        if (parames["end_port"]) {
            arr = arr.filter(f => (f?.port == parames?.end_port))
        }
        if (parames["eta"]) {
            arr = arr.filter(f => f?.daysFromNow > 0 && f?.daysFromNow && parames?.eta >= f?.daysFromNow)
        }
        return arr
    }
    // 装货港 卸货港 eta 搜索
    const handleSelect = (v, t) => {
        setselectValues({ ...selectValues, [t]: v });
        console.log('setselectValues', selectValues);
        let parames = { ...selectValues, [t]: v };
        setBileList(filterBileList(BileListOld, parames))
    }

    // 装货港 卸货港 eta 清除搜索
    const handleClear = (t) => {
        setselectValues({ ...selectValues, [t]: '' });
        let parames = { ...selectValues, [t]: '' };
        setBileList(filterBileList(BileListOld, parames))
    }
    const t = (m) => {
        if (!m?.tags || m?.tags?.length == 0) {
            return (<>
                <span>
                    创建于 {moment(m?.business?.gmtCreate).format("YYYY-MM-DD") || " "}
                </span>
            </>)
        }
        return (<>
            <span>ETA {m?.eta || "-"}</span><Divider type="vertical" /><span>
                创建于 {moment(m?.business?.gmtCreate).format("YYYY-MM-DD") || " "}
            </span>
        </>)
    }
    const renderItemsChildren = () => {
        const cornerPopover = (m) => (<div className="item_right_corner_content scrollbar_custom" >
            {m?.noteList?.map((n, i) =>
                <div key={i} className="item_right_corner_item" style={{ marginTop: i > 0 ? 8 : 0 }}>
                    {n}

                </div>)}
        </div>)
        return (
            <div className="custom_tabs_content enterpress_tabs_content">
                <div className="custom_person">
                    <div>{selectedItem?.name || "全部"}  </div>
                    <Space size={[8, 8]} style={{ fontWeight: 400 }}>
                        <span>非协作 <a>{visibleSum}</a></span>
                        <span>协作 <a>{workflowSum}</a></span>
                    </Space>
                </div>
                <div className="list_info_wrap">
                    <SearchHeaderTitle ref={refSearchHeaderTitle} className="custom_search_style" getSelectValue={getSelectValue} title=""></SearchHeaderTitle>
                    <Divider></Divider>
                    <div className="select_group">
                        <Space size={[12, 12]}>
                            <Select
                                placeholder="装货港"
                                allowClear={true}
                                style={{ width: 96 }}
                                onClear={() => handleClear('start_port')}
                                onSelect={(v) => handleSelect(v, 'start_port')}
                                options={startPorts}
                            ></Select>
                            <Select
                                // open={true}
                                onClear={() => handleClear('end_port')}
                                onSelect={(v) => handleSelect(v, 'end_port')}
                                allowClear={true}
                                placeholder="卸货港"
                                style={{ width: 96 }}
                                options={endPorts}
                            ></Select>
                            <Select
                                allowClear={true}
                                placeholder="ETA"
                                style={{ width: 120 }}
                                onClear={() => handleClear('eta')}
                                onSelect={(v) => handleSelect(v, 'eta')}
                                options={[
                                    { value: '1', label: '今日' },
                                    { value: '3', label: '三天' },
                                    { value: '7', label: '一周' },
                                ]}
                            // open={true}

                            ></Select>
                        </Space>
                    </div>
                    <div className="list_wrap">
                        <div className="list_content show_personage_bill_info">
                            <div className="item_container ">

                                {BileList.length == 0 ? <div className="item_empty_enterpress">暂无业务，请前往「工作台」创建</div> :
                                    BileList.map((m, i) => <div onClick={() => handleItem(m)}
                                        className={classnames({ 'item_actived': m?.active, [`item_${m?.business?.subType || m?.business?.type}`]: true, 'notTags': !m?.tags || m?.tags?.length == 0 }, 'item_wrap')} key={i}>
                                        {m?.noteList && m?.noteList?.length > 0 && <Popover
                                            overlayClassName="popover_custom_corner"
                                            className="item_right_corner"
                                            // trigger="click"
                                            content={cornerPopover(m)}
                                            placement="right">
                                            <div className="item_right_corner3">
                                                <div className="item_icon">
                                                    <MenuOutlined style={{ fontSize: 8, color: "#0166ff" }} />
                                                </div>
                                            </div>
                                        </Popover>}
                                        <div className="item_title">
                                            <div>
                                                {m?.contractNo ?
                                                    <span style={{ color: "rgba(0,0,0,0.8)", fontWeight: 500 }}>{
                                                        m?.contractNo} <span style={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}> -{m?.code}</span>
                                                    </span>
                                                    : <span style={{ color: "rgba(0,0,0,0.8)", fontWeight: 500 }}>{m?.code}</span>
                                                }
                                            </div>
                                            <a style={{ fontSize: 12, fontWeight: 400, color: "#0166ff" }}> {m?.port}</a>
                                        </div>
                                        <div className="item_content">
                                            {m?.tags?.filter(m => m)?.map((item, index) => (
                                                <span key={index}>{item}</span>
                                            ))}
                                            {m?.profit?.profit && <span style={{ color: m?.profit?.profit > 0 ? 'red' : "rgba(42, 192, 97, 1)" }}>盈亏 &nbsp;{m?.profit?.profit > 0 ? "+" : ""}{parseInt(m?.profit?.profit).toLocaleString()}</span>}

                                        </div>
                                        <div className="item_tiem">
                                            {t(m)}

                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const handleEnterPressPerson = ({ code, name }) => {
        setselectedItem(() => ({ name, code }))
        console.log(code);
    }
    let tabItem = [
        {
            key: 'enterpress',
            label: (<div className="custom_tab_label"> <TeamOutlined style={{ fontSize: 20 }} /> 员工管理</div>),
            children:
                <div className="enterpress_content">
                    <div className="enterpress_name">{localStorage.getItem("companyName")}</div>
                    <div className="list_content enterpress_content">
                        {/* <div className="item_container "> */}
                        <SelectSalesmanForBoss handleSelect={props.handleSelect} handleEnterPressPerson={handleEnterPressPerson} />
                        {/* </div> */}
                    </div>
                </div>
            ,
        },
        {
            key: 'all',
            label: (<div className="custom_tab_label"> <ProjectOutlined style={{ fontSize: 20 }} /> 所有业务</div>),
            children: renderItemsChildren()
            ,
        },
        {
            key: 'visible',
            label: (<div className="custom_tab_label"> <EnvironmentOutlined style={{ fontSize: 20 }} />非协业务 </div>),
            children: renderItemsChildren(),
        },

        {
            key: 'workflow',
            label: (<div className="custom_tab_label">{currentKey != 'workflow' ? <img className="anticon partition_active" src={PartitionActive} /> : <img className="anticon partition" src={Partition} />}协作业务 </div>),
            children: renderItemsChildren(),
        }
    ]


    const renderTabBar = (props, DefaultTabBar) => (
        <div
            className="current_render_tabs"
        >
            <DefaultTabBar
                {...props}
            />
            {!hiddenContent && <span onClick={() => sethiddenContent(!hiddenContent)} className="close_leftcontent"><img src={arrow} /></span>}
        </div>
    );
    return (
        // destroyInactiveTabPane = { true}
        <Tabs destroyInactiveTabPane={true} tabPosition="left" tabBarGutter={16} className={hiddenContent ? "hiddle_tabs_holder map_left_info_container" : "map_left_info_container"} renderTabBar={renderTabBar} items={tabItem} onTabClick={tabClick} onChange={onChange} />

    )
}