import { message, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { OnlineVedioApi } from '@/api'
import { v4 as uuid } from 'uuid'
import OnlineVedio from '../OnlineVedio';
function OnlineVedioModal(props) {
    console.log()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isShowOnlineVedio, setOnlineVedio] = useState(false)
    const [accessToken, setAccessToken] = useState("")
    const [url, setUrl] = useState("")
    const [isModalOpenLook, setIsModalOpenLook] = useState(false)
    console.log(props.pic, "---------2222222------")
    const { deviceSerialId, deviceChannelNo, vid } = props
    const [id, setId] = useState(uuid())
    const [timer, setTimer] = useState(null)
    const [Isplaying, setIsplaying] = useState(false);
    let ChildRef_bank = React.createRef()

    useEffect(() => {
        getVedio()
        setIsplaying(false);
        return () => {
            setOnlineVedio(false)
            if (timer) {
                clearTimeout(timer)
            }
        }

    }, [vid])

    // 获取可绑定list
    const getVedio = async () => {
        const p = {
            deviceChannelNo,
            deviceSerialId,
        }
        let { data: { accessToken, video } } = await OnlineVedioApi(p)
        console.log("=======data======", accessToken, video)
        if (!accessToken || !video) {
            message.warning("摄像头已离线");
            setIsModalVisible(false)
            return false;
        }
        setIsModalVisible(true)

        setAccessToken(accessToken)
        setUrl(video)
        setOnlineVedio(true)
        setId(uuid())
    }
    const look = () => {
        setIsModalOpenLook(true);
    }
    const handleOk = () => {
        setIsModalOpenLook(false)
    }
    const handleCancelLook = () => { setIsModalOpenLook(false); };

    const handleCancel = () => {
        try {

            console.log(ChildRef_bank.current.stopVedio())
        } catch (error) {
            console.log('handlecancel', error);
        }

        const timerOne = setTimeout(() => {
            setOnlineVedio(false)
            setIsModalVisible(false)
        }, 300);
        setTimer(timerOne)
    }
    // destroyOnClose = { true}
    return (<div>
        <Modal
            open={isModalVisible}
            destroyOnClose={true}
            style={{ top: 20 }}
            width={1020}
            footer={false}
            onCancel={handleCancel}>
            <div style={{ marginTop: 15, position: "relative" }} >
                {isShowOnlineVedio &&
                    <OnlineVedio
                        onRef={ChildRef_bank}
                        uuid={id}
                        accessToken={accessToken}
                        url={url}
                        setIsplaying={setIsplaying}
                    ></OnlineVedio>}

                {Isplaying && props.mosaic && (<div style={{ marginTop: 48, position: 'absolute', top: 0, zIndex: 999999 }}>
                    <img
                        src={props.mosaic}
                        // onClick={look}
                        style={{
                            height: 452,
                            width: 970,

                        }}
                    ></img>
                </div>)}
                {Isplaying && props.pic && (<div style={{ marginTop: 10 }}>
                    参照图：
                    <img
                        src={props.pic}
                        onClick={look}
                        style={{
                            height: 45,
                            width: 45,

                        }}
                    ></img>
                </div>)}
            </div>
        </Modal >
        {
            isModalOpenLook && (
                <Modal
                    title=""
                    open={isModalOpenLook}
                    onOk={handleOk}
                    footer={null}
                    width={1000}
                    onCancel={handleCancelLook}
                >
                    <img
                        src={props.pic}
                        style={{
                            textAlign: 'center',
                            height: "450px",
                            width: "95%",
                        }}
                    ></img>
                </Modal>
            )
        }
    </div >)
}

export default OnlineVedioModal